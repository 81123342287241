export default {
  data() {
    return {
      texts: "",
      menuHeight: 0,
      menuHeightResp: 0,
      activeCatalogGroup: false,
      drawer: null,
      mini: false,
      right: null,
      downList: false,
      open: true,
    };
  },
  beforeMount() {
    this.texts = FILE.menuTexts[this.selectLanguage];
  },
  mounted(){
    this.$nextTick(()=>{
      this.openMenu(this.bMenuSidebar);
    })
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (
      this.$route.name == "Categories" ||
      this.$route.name == "Subcategories"
    ) {
      this.downList = true;
      this.activeCatalogGroup = true;
    }
    
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    setMenuSidebar() {
      var active = !this.bMenuSidebar
      this.$store.commit("setMenuSidebar", {
        active: active,
      });
    },
    toggle() {
      this.open = !this.open;
    },
    getPermitsById(item) {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/permissions/${this.$store.state.sAdministratorId}`,
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
          params: {},
        }
      )
        .then((response) => {
          if (this.$store.state.permissions !== null) {
            this.$store.commit("setPermissions", response.data.results);
          }

          if (
            this.$route.name.toUpperCase() !==
            item.href.substr(7, item.href.length).toUpperCase()
          ) {
            if (this.$store.state.permissions !== null) {
              if (
                this.$store.state.permissions
                  .map((e) => e.sPermissionId)
                  .includes(item.permission)
              ) {
                this.$router.push(item.href);
              }
            } else {
              this.$router.push(item.href);
            }
            this.$store.commit("setMenuSidebar", {active:false});
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    setBackgroundColor: function(routeNames) {
      let bln = false;
      for (let i = 0; i < routeNames.length; i++) {
        if (this.$route.name == routeNames[i]) {
          bln = true;
        }
      }
      return bln;
    },
    setPositionsList: function(routeNames) {
      let bln = false;
      for (let i = 0; i < routeNames.length; i++) {
        if (this.$route.name == routeNames[i]) {
          bln = true;
        }
      }
      return bln;
    },
    handleResize: function() {
      this.menuHeight = window.innerHeight - 249;
      this.menuHeightResp = window.innerHeight - 250;
    },
    killSession() {
      if (this.$store.state.sToken !== "") {
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };

        DB.delete(`${URI}/api/v1/${this.selectLanguage}/session`, config)
          .then((response) => {
            localStorage.clear();
            this.$store.commit("setToken", "");
            // this.$router.push("/");
            this.$router.go();
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      }
    },
    changeLanguage(val) {
      this.$store.commit("toggle_language", val);
    },
    openMenu(sidebar) {
      var menu = document.getElementById("Menu");
      if (sidebar) {
        menu.classList.add("v-container-menu-negative");
      } else {
        menu.classList.remove("v-container-menu-negative");
      }
    },
  },
  computed: {
    filteredItems: function() {
      return this.texts.items.filter((routeOption) => {
        if (this.userPermissions === null) {
          return routeOption;
        } else {
          var exp = this.userPermissions;
          let arr = "";
          exp.forEach((e) => {
            if (e.sPermissionId === routeOption.id) {
              arr = e;
            }
          });
          return arr;
        }
      });
    },
    bMenuSidebar() {
      if (
        this.$route.name == "Categories" ||
        this.$route.name == "Subcategories"
      ) {
        this.activeCatalogGroup = true;
      } else {
        this.activeCatalogGroup = false;
      }
      return this.$store.state.bMenuSidebar;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
    userPermissions() {
      return this.$store.state.permissions;
    },
  },
  watch: {
    userPermissions() {
    },
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.menuTexts[this.selectLanguage];
      }
    },
    bMenuSidebar: function() {
      this.openMenu(this.bMenuSidebar);
      // var menu = document.getElementById("Menu");
      // if (this.bMenuSidebar) {
      //   menu.classList.add("v-container-menu-negative");
      // } else {
      //   menu.classList.remove("v-container-menu-negative");
      // }
    },
  },
};
